import lowerdev from '@/service/lowerdev';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import ClipboardJS from 'clipboard';
import domain from '@/service/domain';
import { env, domTop, domHeight, domClientHeight } from 'md-base-tools/env';
import { get } from '@/utils/apiBase.js';
import { showSuccessToast } from 'vant';
import { mdToast } from '../../utils/tools';
export default {
  data() {
    return {
      placeholder: require('@assets/images/placeholder/default_1x1@2x.png'),
      copyboard: null,
      dialog_show: false,
      loading: false,
      dialog_src: '',
      dialog_btn: [],
      dialog_used: 1,
      page: 1
    };
  },
  components: {},
  async setup() {
    const route = useRoute();
    const orderid = route.params.orderid || '';
    const {
      data
    } = await get('/apis/mdorder/delivers', {
      order_id: orderid,
      page: 1,
      page_size: 10
    });
    console.log(data);
    return {
      orderid,
      top_title: data.top_title,
      list: ref(data.list),
      is_next: data.is_next
    };
  },
  watch: {},
  computed: {
    isShow() {
      return !(env.isModianIos() || env.isModianAndroid() || env.isWxApp() || env.isWxApp() || env.isWeixin());
    }
  },
  mounted() {
    console.log(domTop(), domHeight(), domClientHeight());
    lowerdev.addbind(window, 'scroll', this.reloadScroll);
  },
  beforeUnmount() {
    lowerdev.removebind(window, 'scroll', this.reloadScroll);
  },
  methods: {
    reloadScroll() {
      const localTop = domTop();
      if (this.loading || !this.is_next) {
        return false;
      }
      if (localTop && domHeight() < localTop + domClientHeight() + 50) {
        this.page++;
        this.loading = true;
        this.getlist();
      }
    },
    async getlist() {
      const {
        data
      } = await get('/apis/mdorder/delivers', {
        order_id: this.orderid,
        page: this.page,
        page_size: 10
      });
      this.loading = false;
      this.list.push(...data.list);
      this.is_next = data.is_next;
    },
    dialog(index) {
      this.index = index;
      this.dialog_src = this.list[index].qr_info.qrcode_pic;
      this.dialog_used = this.list[index].qr_info.qr_use;
      this.dialog_show = true;
    },
    close_dialog() {
      this.dialog_show = false;
    },
    goDetail(expressName, expressNo) {
      this.$router.push(`/order/logistics/info?express_name=${expressName}&express_no=${expressNo}&order_id=${this.orderid}`);
    },
    copy(i) {
      document.getElementsByTagName('input')[i].select();
      try {
        if (document.execCommand('copy', false, null)) {
          showSuccessToast('复制成功');
        } else {
          // IOS不支持时候重新初始化Clipboard
          var copyTarget = this.$refs.copyboard[i];
          var copyboard = new ClipboardJS(copyTarget, {
            text(copyTarget) {
              return copyTarget.getAttribute('copyData');
            }
          });
          copyboard.on('success', () => {
            showSuccessToast('复制成功');
          });
        }
      } catch (err) {
        mdToast(err);
      }
    },
    jumpNft(item) {
      location.href = `${domain.wap}/digital/detail/${item.nft_info.nft_stock_hash}.html`;
    },
    jumpMusic(item) {
      const config = {
        type: 'md://album_list?id=' + item.music_info.music_id,
        open: true
      };
      console.log(this.$store);
      this.$store.dispatch('currentlLinkUrlAction', config);
    },
    dropBack() {
      if (history.length > 1) {
        this.$router.back(-1);
      } else {
        lowerdev.openUrl();
      }
    }
  }
};